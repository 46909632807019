<template>
  <div class="master__tender__cell">
    <div class="master__tender__cell__date__and__price__container">
      <div class="master__tender__cell__date">{{ tender.created_at }}</div>
      <!--updated_at or created?  -->
      <div class="master__tender__cell__date">
        с {{ tender.planned_start_date }} по {{ tender.planned_end_date }}
      </div>{{ tender.planned_end_date }}
      <div class="master__tender__cell__price">
        от {{ tender.planned_start_price }} руб. до
        {{ tender.planned_end_price }} руб.
      </div>
    </div>
    <div class="master__tender__cell__title__and__status__container">
      <div class="master__tender__cell__title">{{ tender.name }}</div>
      <div class="master__tender__cell__pre-word-status">
        статус:
         <div :class="`master__tender__cell__status__${tender.status.id}`">
          <span
            :style="{
            display: 'inline-block',
            width: '14px',
            textAlign: 'center',
          }"
          ></span>
          {{ tender.status.name }}
        </div>
      </div>
    </div>
    <div class="master__tender__cell__services__work">
      <div class="master__tender__cell__services__work__items">
        <ServiceWork
          v-for="subtag in tender.subtags"
          :key="subtag.subtagId"
          :name="subtag.name"
          :color="subtag.color"
        />
      </div>

      <div
        v-if="isUnreadMessages"
        class="master__tender__cell__chat__unmessages__icons"
      >
      <router-link
        :to="{
          name: `Tender`,
          params: {
            uuid: tender.uuid,
          },
        }"
      >
        <img src="@/assets/image/chatIcon.svg" />
        <a-badge
          class="master__tender__cell__chat__unmessages__icons__badge"
          :number-style="{ backgroundColor: '#B12021', borderRadius: '3px', marginTop: '10px' }"
          count="new!"
        >
          <a href="#" class="master__tender__cell__head__example" />
        </a-badge>
      </router-link>
      </div>
    </div>
    <div class="master__tender__cell__description">
      {{ tender.detailText }}
    </div>
    <div class="master__tender__cell__button__container">
      <router-link
        :to="{
          name: `Tender`,
          params: {
            uuid: tender.uuid,
          },
        }"
      >
        <Button class="red-antd-button">Подробнее</Button>
      </router-link>
    </div>
  </div>
</template>

<script>
import ServiceWork from '@/components/common/ServiceWork.vue'
import Button from '@/components/common/Button.vue'

export default {
  components: {
    ServiceWork,
    Button
  },
  props: ['tender', 'comingUserMessages'],
  computed: {
    isUnreadMessages () {
      if (this.comingUserMessages === 1) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss">
.master__tender__cell {
  display: flex;
  flex-direction: column;
  padding: 26px 20px 40px 30px;
  justify-content: space-around;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  background-color: white;
  border-radius: 3px;
  &.current__user {
    display: flex;
    flex-direction: column;
    padding: 26px 20px 40px 30px;
    justify-content: space-around;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    background-color: white;
    border-radius: 3px;
    box-shadow: 0px 1px 12px -4px #fa491c;
  }
  &__date__and__price__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__date {
    color: #989898;
    text-align: left;
  }

  &__price {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: black;
  }

  &__title__and__status__container {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: black;
  }

  &__pre-word-status {
    display: flex;
    flex-direction: row;
  }

  &__status {
    &__1 {
      color: #7fb56c;
    }
    &__2 {
      color: #b5b46c;
    }
    &__3 {
      color: #fa491c;
    }
  }

  &__services__work {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
  }

  &__chat__unmessages__icons__badge {
    margin-right: 20px;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    margin-top: 12px;
    padding-right: 35%;
  }
  &__button__container {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    height: 42px;
    /* width: 160px; */
    background-color: #b12021;
    color: white;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .master__tender__cell {
    &__date__and__price__container {
      flex-direction: column;
      justify-content: flex-start;
    }
    &__title__and__status__container {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

@media(max-width: 768px) {
  .master__tender__cell {
    padding: 20px;
    &__title{
      margin-bottom: 10px;
    }
    &__price {
      text-align: start;
    }
    &__pre-word-status{
      flex-direction: column;
    }
  }
}

@media(max-width: 576px) {
  .master__tender__cell {
    &__title{
     font-size: 18px;
    }
    &__price {
    }
    &__pre-word-status{
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

</style>
