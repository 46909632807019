var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"master__tender__cell"},[_c('div',{staticClass:"master__tender__cell__date__and__price__container"},[_c('div',{staticClass:"master__tender__cell__date"},[_vm._v(_vm._s(_vm.tender.created_at))]),_c('div',{staticClass:"master__tender__cell__date"},[_vm._v(" с "+_vm._s(_vm.tender.planned_start_date)+" по "+_vm._s(_vm.tender.planned_end_date)+" ")]),_vm._v(_vm._s(_vm.tender.planned_end_date)+" "),_c('div',{staticClass:"master__tender__cell__price"},[_vm._v(" от "+_vm._s(_vm.tender.planned_start_price)+" руб. до "+_vm._s(_vm.tender.planned_end_price)+" руб. ")])]),_c('div',{staticClass:"master__tender__cell__title__and__status__container"},[_c('div',{staticClass:"master__tender__cell__title"},[_vm._v(_vm._s(_vm.tender.name))]),_c('div',{staticClass:"master__tender__cell__pre-word-status"},[_vm._v(" статус: "),_c('div',{class:`master__tender__cell__status__${_vm.tender.status.id}`},[_c('span',{style:({
          display: 'inline-block',
          width: '14px',
          textAlign: 'center',
        })}),_vm._v(" "+_vm._s(_vm.tender.status.name)+" ")])])]),_c('div',{staticClass:"master__tender__cell__services__work"},[_c('div',{staticClass:"master__tender__cell__services__work__items"},_vm._l((_vm.tender.subtags),function(subtag){return _c('ServiceWork',{key:subtag.subtagId,attrs:{"name":subtag.name,"color":subtag.color}})}),1),(_vm.isUnreadMessages)?_c('div',{staticClass:"master__tender__cell__chat__unmessages__icons"},[_c('router-link',{attrs:{"to":{
        name: `Tender`,
        params: {
          uuid: _vm.tender.uuid,
        },
      }}},[_c('img',{attrs:{"src":require("@/assets/image/chatIcon.svg")}}),_c('a-badge',{staticClass:"master__tender__cell__chat__unmessages__icons__badge",attrs:{"number-style":{ backgroundColor: '#B12021', borderRadius: '3px', marginTop: '10px' },"count":"new!"}},[_c('a',{staticClass:"master__tender__cell__head__example",attrs:{"href":"#"}})])],1)],1):_vm._e()]),_c('div',{staticClass:"master__tender__cell__description"},[_vm._v(" "+_vm._s(_vm.tender.detailText)+" ")]),_c('div',{staticClass:"master__tender__cell__button__container"},[_c('router-link',{attrs:{"to":{
        name: `Tender`,
        params: {
          uuid: _vm.tender.uuid,
        },
      }}},[_c('Button',{staticClass:"red-antd-button"},[_vm._v("Подробнее")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }