<template>
  <div class="master__main__page">
    <div class="master__main__page__header">
      <div class="master__main__page__header__container">
        <div class="master__main__page__header__title">Список тендеров</div>
        <div class="master__main__page__header__input">
          <a-select
            placeholder="Поиск"
            size="large"
            @change="choseSubtags"
            showArrow
            mode="multiple"
            class="master__main__page__select tags"
            :maxTagCount="2"
            :maxTagTextLength="17"
            v-model:value="queryTags"
          >
            <a-select-option v-for="i in getWorkTagsValue()" :key="i.id" >
              {{ i.name }}
            </a-select-option>
          </a-select>

          <a-select
              show-search
              size="large"
              @change="choseSubtags"
              class="master__main__page__select"
              placeholder="Москва,Россия"
              v-model:value="queryCity"
          >
            <a-select-option v-for="i in getCities()" :key="i.name"  @click="choseCity(i.name)">
              {{ i.name }}
            </a-select-option>
          </a-select>
          <Button v-if="this.$store.getters.getOrdersLoaded" @click="availableSubTags()" style="height: 40px; width: 200px;" buttonClass="red-antd-button">
            Доступные тендеры
          </Button>
        </div>
      </div>
    </div>

    <TabViewContainer>
      <Loader v-if="!this.$store.getters.getAllLoaded && !this.$store.getters.getOrdersLoaded" />
      <div v-if="this.$store.getters.getAllLoaded && this.$store.getters.getAllTenders.length > 0"  class="cell_wrapper">
        <MasterTenderCell
          v-for="tenderEl in this.$store.getters.getAllTenders"
          :key="tenderEl.id"
          :tender="tenderEl"
          :comingUserMessages="tenderEl.new_message_user"
          class="announcement-doing-cell"
        ></MasterTenderCell>
      </div>

      <div v-if="this.$store.getters.getAllLoaded && this.$store.getters.getAllTenders.length >0" class="pagination">
        <a-pagination
          :current="currentPageAllTenders"
          :total="this.$store.getters.getAllTotalTenders"
          :showLessItems="true"
          show-less-items
          :defaultPageSize="6"
          :hideOnSinglePage="true"
          @change="onChangePagination"
        />
      </div>
      <div v-if="this.$store.getters.getAllLoaded && this.$store.getters.getAllTenders.length == 0" class="no-tenders">
        Не найдено ни одного тендера с запрашиваемыми фильтрами
      </div>
    </TabViewContainer>
  </div>
</template>

<script>
import MasterTenderCell from '@/components/MasterComponents/MasterTenderCell.vue'
import TabViewContainer from '@/components/common/TabViewContainer.vue'
import EmptyVue from '@/components/common/EmptyVue.vue'
import Loader from '@/components/common/Loader.vue'
import Button from '@/components/common/Button.vue'

export default {
  async beforeMount() {
    await this.$store.dispatch('setSubTags')
    await this.$store.dispatch('setTags')
    await this.$store.dispatch('setCities', true)
    await this.$store.dispatch('setOrders')
  },
   mounted () {
     this.queryCity = this.$route.query.city
     this.queryTags = this.$route.query.tags ? [Number(this.$route.query.tags)] : []
     this.$store.dispatch('setAllTenders', {
       array: this.$store.getters.getAllTenders,
       page: this.currentPageAllTenders,
       tags: this.queryTags,
       address: this.queryCity
     })
  },

  components: {
    MasterTenderCell,
    TabViewContainer,
    EmptyVue,
    Loader,
    Button
  },
  data () {
    return {
      breadcrumbs: [
        {
          id: 1,
          name: 'Главная',
          link: '/main'
        }
      ],
      currentPageAllTenders: 1,
      tags: [],
      queryCity: '',
      queryTags: []
    }
  },
  computed() {

  },
  methods: {
    onChangePagination (current) {
      this.currentPageAllTenders = current
      this.$store.dispatch('setAllTenders', {
        array: this.$store.getters.getMasterActiveTenders,
        page: this.currentPageAllTenders,
        tags: this.queryTags,
        address: this.queryCity
      })
    },
    getWorkTagsValue () {
      let arr = this.$store.getters.getSubTags
      return arr
    },
    getCities () {
      return this.$store.getters.getCities
    },
    choseCity (id) {
      console.log('choseCity', this.queryCity)
      this.currentPageAllTenders = 1
      this.$store.dispatch('setAllTenders', {
        array: this.$store.getters.getMasterActiveTenders,
        page: this.currentPageAllTenders,
        tags: this.queryTags,
        address:  id
      })
    },
    choseSubtags () {
      this.currentPageAllTenders = 1
      this.$store.dispatch('setAllTenders', {
        array: this.$store.getters.getMasterActiveTenders,
        page: this.currentPageAllTenders,
        tags: this.queryTags,
        address: this.queryCity
      })
    },
    availableSubTags() {
      if (this.$store.getters.getOrders && this.$store.getters.getOrders.length > 0) {
        this.queryTags = this.$store.getters.getOrders.map((item) => item.subtags[0].id)
      }
      this.choseSubtags()
    }
  }
}
</script>

<style lang="scss">
.no-tenders {
  background-color: white;
  width: 100%;
  height: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.master__main__page {
  background-color: white;
  width: 100%;
  height: 100%;
  &__select {
    width: 100%;
    min-width: 200px;
    max-width: 300px;
    z-index: 9;
    &.tags{
      min-width: 500px;
      max-width: 500px;
    }
    &__option {
      background-color: #b12021;
      color: white;
    }
  }

  &__header {
    padding-right: 15%;
    padding-left: 20px;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 100%;
    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 20px;
    }
    &__input {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: flex-end;
      &-search {
        width: 400px;
      }
    }

    &__title {
      display: flex;

      justify-content: flex-start;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}

@media (max-width: 992px) {
  .master__main__page {
    &__header {
      padding-right: 10%;
    }
  }
}

@media (max-width: 768px) {
  .master__main__page {
    &__header {
      &__input {
        &-search {
          width: 100%;
        }
      }
    }
  }
}
.profile-label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.announcement-orders {
  width: 100%;
  height: 100%;
  padding-right: 15%;
  background-color: #f6f6f6;
  padding-left: 20px;
  padding-top: 7px;
}

.announcement-doing-page {
  background-color: #f6f6f6;
  padding-right: 15%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  padding-top: 17px;
}

.announcement-doing-cell {
  margin-top: 20px;
  box-shadow: 0px 1px 12px -4px rgba(34, 60, 80, 0.2);
  background-color: white;
  border-radius: 8px;
}

.pagination {
  width: 100%;

  display: flex;
  justify-content: flex-end;

  margin-top: 46px;
}

@media (max-width: 768px) {
  .master__main__page {
    &__select {
      width: 100%;
      &.tags{
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 600px) {
  .master__main__page {
    &__header {
      padding-right: 5%;
    }
    &__select {
      width: 100%;
      max-width: 100%;
    }
  }
}

.cell_wrapper{
  width: 100%;
}
</style>
